import { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
} from "@material-ui/core";
import Card from "./card";
import "./app.scss";
import memoryLogo from "./images/memoryLogo.png";
import spinner from "./images/hoot-spinner.gif";

const Hapi = "https://www.cryptohoots.com/api/hoots";

// Had to add this or on some servers, localStorage was not being created
if (!localStorage.getItem("bestScore")) {
  localStorage.setItem("bestScore", "9999999");
}

export default function App() {
  const [randomHoots, setRandomHoots] = useState([]);
  const [cards, setCards] = useState([]);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("Loading");
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || "69696969"
  );
  const timeout = useRef(null);
  const [loading, setLoading] = useState(true);

  // initialize a new round with 16 unique cards
  async function getHoots() {
    let hootArray = [];
    for (let i = 0; i < 16; ++i) {
      // first pull n random numbers from 0 to 2499
      let randomID = Math.floor(Math.random() * 2500);
      const response = await fetch(Hapi + "/" + randomID);
      hootArray.push(await response.json());
    }
    setRandomHoots(hootArray);
  }

  useEffect(() => {
    async function loadHoots() {
      try {
        setLoading(true);
        await getHoots();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    if (cards.length === 0) {
      loadHoots();
    }
  }, []);

  function shuffleCards(array) {
    const length = array.length;
    for (let i = length; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * i);
      const currentIndex = i - 1;
      const temp = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temp;
    }
    return array;
  }

  useEffect(() => {
    if (randomHoots !== [])
      setCards(shuffleCards(randomHoots.concat(randomHoots)));
  }, [randomHoots]);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  // Board can be empty because of victory or loading
  const checkEmptyBoard = () => {
    if (
      Object.keys(clearedCards).length === randomHoots.length &&
      Object.keys(clearedCards).length !== 0
    ) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", JSON.stringify(highScore));
      setModalText("HOOT HOOT! You did it");
    }
  };

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].name === cards[second].name) {
      setClearedCards((prev) => ({ ...prev, [cards[first].name]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };

  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    if (clearedCards) {
      checkEmptyBoard();
    }
  }, [clearedCards]);

  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.name]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    setCards(shuffleCards(cards));
  };

  return (
    <div className="App">
      <header>
        <img src={memoryLogo} />
        <div>Find All Matching Hoots</div>
        <div>Reshuffle the same cards or reload page for new cards</div>
      </header>
      <div className="container">
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              card={card}
              index={index}
              isDisabled={shouldDisableAllCards}
              isInactive={checkIsInactive(card)}
              isFlipped={checkIsFlipped(index)}
              onClick={handleCardClick}
            />
          );
        })}
      </div>
      <footer>
        <div className="score">
          <div className="moves">
            <span className="bold">Moves:</span> {moves}
          </div>
          {localStorage.getItem("bestScore") && (
            <div className="high-score">
              <span className="bold">Best Score:</span> {bestScore}
            </div>
          )}
        </div>
        <div className="restart">
          <Button onClick={handleRestart} color="primary" variant="contained">
            Reshuffle
          </Button>
        </div>
      </footer>
      {loading && (
        <div className="loader">
          <p> Loading</p>
          <p>
            <img src={spinner} />
          </p>
        </div>
      )}
      <Dialog
        open={showModal}
        disableEscapeKeyDown
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modalText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You completed the game in {moves} moves. Your best score is{" "}
            {bestScore} moves.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRestart} color="primary">
            Reshuffle
          </Button>
        </DialogActions>
      </Dialog>
      <div className="credits">Created by Dr Doofus, MD PhD DDS MBA</div>
    </div>
  );
}
